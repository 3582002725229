import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAppleAlt, faBackward, faCalculator, faCalendarAlt, faChartBar, faChartLine, faCloudSunRain, faCopy, faDownload, faEdit, faExclamationTriangle, faFastBackward, faFileAlt, faFilePrescription, faFileUpload, faForward, faHome, faHourglassHalf, faLeaf, faListAlt, faListUl, faMinus, faPlus, faSave, faSeedling, faTh, faTrashAlt, faTree, faUnlockAlt, faUserAstronaut, faUserFriends, faUserPlus, faUsersCog, faVectorSquare, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faEye } from '@fortawesome/free-solid-svg-icons'

library.add(faHome)
library.add(faCloudSunRain)
library.add(faUsersCog)
library.add(faListUl)
library.add(faTree)
library.add(faSeedling)
library.add(faUserFriends)
library.add(faChartLine)
library.add(faUserPlus)
library.add(faEdit)
library.add(faTrashAlt)
library.add(faForward)
library.add(faBackward)
library.add(faSearch)
library.add(faEye)
library.add(faCalendarAlt)
library.add(faHourglassHalf)
library.add(faFileAlt)
library.add(faVectorSquare)
library.add(faFileUpload)
library.add(faFilePrescription)
library.add(faDownload)
library.add(faWindowClose)
library.add(faPlus)
library.add(faSave)
library.add(faUnlockAlt)
library.add(faCopy)
library.add(faTh)
library.add(faAppleAlt)
library.add(faMinus)
library.add(faHome)
library.add(faExclamationTriangle)
library.add(faCalculator)



Vue.component('font-awesome-icon', FontAwesomeIcon)