<template>
  <div id="app">
    <nav-bar-component />
    <router-view />
  </div>
</template>

<script>
import NavBarComponent from './components/NavBarComponent'
import { mapGetters } from "vuex"
export default {
  components: {
    NavBarComponent,
  },
  computed: {
    ...mapGetters(['UsuarioActivo', 'TipoUsuario'])
  }
}
</script>