import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
require("./fontawesome");

import Datepicker from "vuejs-datepicker";

export default {
  components: {
    Datepicker,
  },
  // ...
};

import VueChartkick from "vue-chartkick";
import Chart from "chart.js";
Vue.use(VueChartkick.use(Chart));

import axios from "axios";
import VueAxios from "vue-axios";
import VueSidebarMenu from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

Vue.use(Loading);

Vue.use(VueSidebarMenu);

Vue.use(VueAxios, axios);

// axios.defaults.baseURL = process.env.VUE_APP_API_URL || 'http://localhost:4321/api';// Test
axios.defaults.baseURL =
  process.env.VUE_APP_BASE_URL || "https://plataformaikaros.cl/api"; // Producción
console.log("test: ", process.env.VUE_APP_API_URL);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
